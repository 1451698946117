<!--目标数据审核页面-->
<template>
  <el-container class="layout-container target-data-review-container">
    <el-aside class="container-left">
      <el-container class="layout-container container-left-wrap">
        <el-header class="left-header">
          <el-form :inline="true" :model="searchObj" class="left-form-wrap">
            <div>
              <el-form-item label="">
                <el-radio-group
                  v-model="searchObj.status"
                  size="small"
                  @change="onChangeStatus">
                  <el-radio-button label="">全部</el-radio-button>
                  <el-radio-button :label="1">待审核</el-radio-button>
                  <el-radio-button :label="2">已审核</el-radio-button>
                  <!-- <el-radio-button :label="2">已审核</el-radio-button>
                  <el-radio-button :label="3">不审核</el-radio-button> -->
                </el-radio-group>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="">
                <el-input
                  v-model="searchObj.searchValue"
                  placeholder="药品通用名/生产厂家/药品ID"
                  size="small"
                  clearable
                  style="width: 210px;" />
              </el-form-item>
              <el-form-item label="">
                <el-button type="primary" icon="el-icon-search" size="small" @click="onSearch">查询</el-button>
                <!-- <el-button
                  type="primary"
                  size="small"
                  @click="onSave">
                  保存
                </el-button> -->
                <el-button
                  type="primary"
                  icon="el-icon-s-claim"
                  size="small"
                  @click="onSave">
                  保存
                </el-button>
                <el-button type="primary" icon="el-icon-download" size="small" @click="downloadAndSaveHandle">保存并下载目标数据</el-button>
                <el-button type="primary" icon="el-icon-back" size="small" @click="onBack">返回</el-button>
              </el-form-item>
            </div>
          </el-form>
        </el-header>
        <el-main class="left-main">
          <el-table
            ref="leftTableRef"
            :data="tableData"
            :header-cell-style="{textAlign: 'center', color: '#333'}"
            border
            stripe
            highlight-current-row
            height="100%"
            class="left-table-wrap"
            @row-click="leftTableRowClick">
            <el-table-column
              prop="drugId"
              label="药品ID"
              width="100"
              align="center">
            </el-table-column>
            <el-table-column
              prop="genericName"
              label="药品通用名"
              align="center">
            </el-table-column>
            <el-table-column
              prop="pdpSpecPackTxt"
              label="规格包装">
            </el-table-column>
            <el-table-column
              prop="specText"
              label="药品规格说明"
              align="center">
            </el-table-column>
            <el-table-column
              prop="manufacturerName"
              label="生产厂家"
              align="center">
            </el-table-column>
            <el-table-column
              prop="status"
              label="审核状态"
              width="100">
              <template slot-scope="scope">
                <div :class="statusClass(scope.row.status)">
                  <span>{{ scope.row.fullStatusStr }}</span>
                  <!-- <span>{{ scope.row.status | countFilter(scope.row) }}</span> -->
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-main>
        <el-footer class="left-footer">
          <pagination
            v-show="leftTotal > 0"
            :total="leftTotal"
            :page.sync="searchObj.pageNum"
            :limit.sync="searchObj.pageSize"
            @pagination="getTableHandle"/>
        </el-footer>
      </el-container>
    </el-aside>
    <el-container class="layout-container container-right">
      <el-header class="right-header">
        <h3 class="right-drug-info">
          <svg-icon
            icon-class="drug-icon"
            class="drug-icon"
            fill="#2B60F8"/>
            <span>{{ detailDataObj.drugInfo }}</span>
        </h3>
        <el-form :inline="true" :model="queryDetailsObj" class="right-form-wrap">
          <div>
            <el-form-item label="">
              <el-radio-group
                v-model="queryDetailsObj.status"
                size="small"
                @change="fetchDetailsData">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button :label="1">待审核</el-radio-button>
                <el-radio-button :label="2">已审核</el-radio-button>
                <!-- <el-radio-button :label="2">已审核</el-radio-button>
                <el-radio-button :label="3">不审核</el-radio-button> -->
              </el-radio-group>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="">
              <el-button type="primary" size="small" @click="onSetField">设置审核字段</el-button>
            </el-form-item>
          </div>
        </el-form>
      </el-header>
      <el-main class="right-main">
        <el-table
          :data="tableDetailData"
          :header-cell-style="{textAlign: 'center', color: '#333'}"
          :cell-style="rightTableCellStyle"
          border
          height="100%"
          class="right-table-wrap">
          <el-table-column
            prop="fieldName"
            label="字段名称">
          </el-table-column>
          <el-table-column
            prop="originName"
            label="原始数据">
          </el-table-column>
          <el-table-column
            label="操作"
            width="80"
            align="center">
            <template slot-scope="scope">
              <el-button
                v-if="isShowOperationSvg(scope.row)"
                :disabled="isTransferBtnDisabled"
                type="text"
                @click="onTransfer(scope.row)">
                <svg-icon
                  v-if="isShowOperationSvg(scope.row)"
                  :icon-class="optionClass(scope.row)"
                  style="width: 20px;height: 20px;"
                  class="handle-icon"/>
              </el-button>
              <!-- <svg-icon
                v-if="isShowOperationSvg(scope.row)"
                :icon-class="optionClass(scope.row)"
                style="width: 20px;height: 20px;"
                class="handle-icon"
                @click="onTransfer(scope.row)"/> -->
            </template>
          </el-table-column>
          <el-table-column
            prop="targetName"
            label="目标数据">
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="right-footer">
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" style="margin: 10px 0;">
            <i class="el-icon-warning" style="color: #94AFF7;vertical-align: middle;"></i>
            <span style="margin-left: 3px;font-size: 12px;color: #999;">系统所展示药品信息，仅供参考，不作为使用依据</span>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" style="display: flex;justify-content: flex-end;">
            <el-button type="primary" plain size="small" @click="onPrev">上一条(F1)</el-button>
            <el-button type="primary" plain size="small" @click="onNext">下一条(F2)</el-button>
            <el-button
              v-if="isShowConfirm"
              type="primary"
              size="small"
              class="confirm-btn"
              @click="onConfirm">
              确认审核(F3)
            </el-button>
            <el-button
              v-else
              type="primary"
              size="small"
              class="cancel-btn"
              @click="onCancel">
              撤销审核(F3)
            </el-button>
          </el-col>
        </el-row>
        <!-- <div>
          <i class="el-icon-warning" style="color: #94AFF7;vertical-align: middle;"></i>
          <span style="margin-left: 3px;font-size: 12px;color: #999;">系统所展示药品信息，仅供参考，不作为使用依据</span>
        </div>
        <div>
          <el-button type="primary" plain size="small" @click="onPrev">上一条(F1)</el-button>
          <el-button type="primary" plain size="small" @click="onNext">下一条(F2)</el-button>
          <el-button
            v-if="isShowConfirm"
            type="primary"
            size="small"
            @click="onConfirm">
            确认审核(F3)
          </el-button>
          <el-button
            v-else
            type="primary"
            size="small"
            @click="onCancel"
            @keyup.114.native="onConfirm">
            撤销审核(F3)
          </el-button>
        </div> -->
      </el-footer>
    </el-container>
    <field-dialog
      v-if="isShowDialog"
      :is-show-dialog="isShowDialog"
      :version="this.searchObj.version"
      @closeDialog="onCloseDialog" />
    <save-dialog
      v-if="isShowSaveDialog"
      :dialog-visible="isShowSaveDialog"
      :version="this.searchObj.version"
      @finish="closeAndBack"
      @closeDialog="onCloseSaveDialog" />
    <download-dialog
      ref="download-component"
      :version="searchObj.version"
      @download="saveAndDownload"/>
  </el-container>
</template>
<script>
  import {
    QUERY_OBJ,
    QUERY_DETAILS_OBJ
  } from '@/utils/dm/targetDataReview/data'
  import {
    getDrugsListApi,
    getContrastDataApi,
    updateTargetDataApi,
    updateDrugStatusApi,
    saveAndDownloadApi,
    saveApi,
    getNotReviewCountApi
  } from '@/api/dm/targetDataReview'
  import {
    onStartDownload
  } from '@/utils/downloadFile'
  import FieldDialog from './components/Dialog.vue'
  import SaveDialog from './components/SaveDialog.vue'
  import DownloadDialog from './components/DownloadDialog.vue'
  export default {
    name: 'TargetDataReview',
    components: {
      FieldDialog,
      SaveDialog,
      DownloadDialog
    },
    filters: {
      statusFilter(status) {
        if (status === 1) {
          return '已审核'
        } else if (status === 0) {
          return '未审核'
        } else if (status === 2) {
          return '不审核'
        }
      },
      countFilter(status, row) {
        if (status === 1) {
          return '(' + row.notApprovedCount + ')'
        } else if (status === 2) {
          // 已审核
          return '(' + row.reviewedCount + ')'
        } else if (status === 3) {
          // 不审核
          return '(' + row.notApprovedCount + ')'
        }
      }
    },
    data() {
      return {
        searchObj: this.$deepClone(QUERY_OBJ),
        queryDetailsObj: this.$deepClone(QUERY_DETAILS_OBJ),
        tableData: [],
        tableDetailData: [],
        leftTableCurrentRow: {},
        detailDataObj: {},
        leftTotal: 0,
        rightTotal: 0,
        leftTableIndex: 0, // 左边表格下标
        isShowDialog: false,
        isShowConfirmBtn: true,
        isShowSaveDialog: false
      }
    },
    computed: {
      statusClass() {
        return function (status) {
          // if (status === 0) {
          //   // 未审核
          //   return 'dis-reviewed'
          // } else if (status === 1) {
          //   // 已审核
          //   return 'reviewed'
          // } else if (status === 2) {
          //   // 不审核
          //   return 'non-reviewed'
          // }
          /**
           * 因为后来不审核归类到已审核中，所以之前的状态code变化了
           * **/
          if (status === 1) {
            // 未审核
            return 'non-reviewed'
          } else if (status === 2) {
            // 已审核
            return 'reviewed'
          }
        }
      },
      // 判断是显示‘确认审核’还是‘撤销审核’
      isShowConfirm: {
        // 当前
        get() {
          return this.isShowConfirmBtn
        },
        set(newVal) {
          this.isShowConfirmBtn = newVal
        }
      },
      // 是否显示操作图标
      isShowOperationSvg() {
        return function (row) {
          if ((row.status !== null && row.status !== 0) && row.currUseDefTarget !== null) {
            return true
          } else if (row.currUseDefTarget === null || row.status === 0) {
            return false
          }
        }
      },
      // 操作图标是否置灰（已确认审核的时候置灰）
      isTransferBtnDisabled() {
        if (this.isShowConfirmBtn) {
          return false
        } else {
          return true
        }
      },
      // 判断是否是上海的机构
      showShangHai() {
        const { provinceCode } = this.$store.getters.dept
        if (provinceCode === '310000') {
          return true
        } else {
          return false
        }
      }
    },
    created() {
      // console.log(123)
    },
    async activated() {
      this.searchObj = this.$deepClone(QUERY_OBJ)
      this.queryDetailsObj = this.$deepClone(QUERY_DETAILS_OBJ)
      this.searchObj.version = this.$route.query.version
      this.leftTableIndex = 0
      this.leftTableCurrentRow = {}
      await this.fetchData(this.leftTableIndex)
    },
    mounted() {
      //当前页面监视键盘输入
      document.onkeydown = e => {
        // console.log('键盘输入了')
        //事件对象兼容
        let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
        //F1
        if (e1 && e1.keyCode == 112) {
          window.event.returnValue = false
          this.onPrev()
        } else if (e1 && e1.keyCode === 113) {
          // F2
          this.onNext()
          window.event.returnValue = false
        } else if (e1 && e1.keyCode === 114) {
          // F3
          // console.log('键盘事件')
          if (this.isShowConfirm) {
            this.onConfirm()
          } else {
            this.onCancel()
          }
          
          window.event.returnValue = false
        }
        // window.event.returnValue = false
      }
    },
    methods: {
      async fetchData(index = 0, fn, type = undefined) {
        // 默认index = 0 当上一条下一条操作的时候index可能会变
        let leftRes = await getDrugsListApi(this.searchObj)
        if (leftRes.code === 200) {
          if (!type) {
            this.tableData = leftRes.data.rows || []
            this.leftTotal = leftRes.data.total
          }
          if (this.tableData && this.tableData.length > 0) {
            // this.queryDetailsObj.id = this.tableData[index].id
            if (!fn) {
              this.leftTableCurrentRow = this.tableData[index]
              this.setCurrent(this.tableData[index])
              await this.fetchDetailsData()
            } else {
              fn()
            }
          } else {
            // 此时如果tbaleData = [],那么对应的右边表格的数据肯定是为空的
            this.tableDetailData = []
          }
        }
      },
      async fetchLeftTableData() {
        let leftRes = await getDrugsListApi(this.searchObj)
        if (leftRes.code === 200) {
          this.tableData = leftRes.data.rows || []
          this.leftTotal = leftRes.data.total
        }
      },
      async fetchDetailsData() {
        // this.tableDetailData = []
        if (this.tableData.length === 0) {
          return
        }
        this.queryDetailsObj.id = this.tableData[this.leftTableIndex].id
        let res = await getContrastDataApi(this.queryDetailsObj)
        if (res.code === 200) {
          this.detailDataObj = res.data || {}
          // 如果不是上海的医院，则不显示“上海基本药品标志”“药品标准统编码”
          if (this.showShangHai) {
            this.tableDetailData = res.data.compareList || []
          } else {
            let data = res.data && res.data.compareList.filter(item => {
              return item.fieldCode !== 'ustdCode' && item.fieldCode !== 'shNedFlag'
            })
            this.tableDetailData = data || []
          }
        }
      },
      async getTableHandle() {
        this.leftTableIndex = 0
        this.leftTableCurrentRow = {}
        await this.fetchData(this.leftTableIndex)
      },
      // 查询
      async onSearch() {
        this.leftTableIndex = 0
        this.leftTableCurrentRow = {}
        await this.fetchData(this.leftTableIndex)
      },
      // 返回
      onBack() {
        this.$router.go(-1)
      },
      // 切换左侧tab
      async onChangeStatus(val) {
        this.searchObj.pageNum = 1
        this.leftTableIndex = 0
        this.leftTableCurrentRow = {}
        await this.fetchData(this.leftTableIndex)
      },
      // 右侧表格设置cell-style
      rightTableCellStyle({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          return 'background-color: #F7F9FC'
        }
        // 注：已审核的tab下的数据不标注红色背景
        const diffFlag = columnIndex !== 0 && (row.status === 2 || row.status === 1) && row.currUseDefTarget && this.queryDetailsObj.status !== 2
        if (diffFlag) {
          // row.currUseDefTarget = true 表示使用的是目标数据，表示原始数据和目标数据不一致
          return 'background-color: #FFDBE1'
        }
      },
      // 原始数据和目标数据进行转换
      async onTransfer(row) {
        // 1表示待审核
        let useOrigin = row.currUseDefTarget ? true : false
        const obj = {
          fieldCode: row.fieldCode,
          id: this.detailDataObj.id,
          useOrigin
        }
        let res = await updateTargetDataApi(obj)
        if (res.code === 200) {
          await this.fetchDetailsData()
          // 右边表格原始数据和目标数据进行转换成功之后，左边表格对应的待审核的数量会减一，所以要重新调一次接口
          // await this.fetchData(this.leftTableIndex)
          await this.fetchLeftTableData()
        }
      },
      // 左边表格某一行被点击
      async leftTableRowClick(row, column, event) {
        this.leftTableIndex = this.tableData.findIndex(item => item.id === row.id)
        this.leftTableCurrentRow = this.$deepClone(row)
        if (this.leftTableCurrentRow.status === 2) {
          // 已审核
          this.isShowConfirm = false
        } else if (this.leftTableCurrentRow.status === 1) {
          this.isShowConfirm = true
        }
        await this.fetchDetailsData()
      },
      // 设置表格高亮行
      setCurrent(row) {
        this.$nextTick(() => {
          this.$refs.leftTableRef && this.$refs.leftTableRef.setCurrentRow(row)
          this.leftTableCurrentRow = row
          if (this.leftTableCurrentRow.status === 2) {
            // 已审核
            this.isShowConfirm = false
          } else if (this.leftTableCurrentRow.status === 1) {
            this.isShowConfirm = true
          }
        })
      },
      // 点击保存按钮，先判断是否满足保存或者下载的条件
      async onSave() {
        const obj = {
          version: this.searchObj.version
        }
        let countRes = await getNotReviewCountApi(obj)
        if (countRes && countRes.data > 0) {
          this.$confirm('当前尚存在待审核数据，是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            // this.isShowSaveDialog = true
            await this.saveData()
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })        
          })
          return
        } else {
          // this.isShowSaveDialog = true
          await this.saveData()
        }
      },
      // 保存或下载之后关闭当前页面，回到上一级页面
      closeAndBack() {
        this.isShowSaveDialog = false
        // 关闭当前页
        this.$store.dispatch('tagsView/delView', this.$route)
        // 返回到上一页
        this.$router.go(-1)
      },
      onCloseSaveDialog(data) {
        this.isShowSaveDialog = false
      },
      // 仅保存
      async saveData() {
        const obj = {
          version: this.searchObj.version
        }
        let res = await saveApi(obj)
        if (res.code === 200) {
          // 关闭当前页
          this.$store.dispatch('tagsView/delView', this.$route)
          // 返回到上一页
          this.$router.go(-1)
        }
      },
      // 保存并下载目标数据
      async onSaveAndDownload() {
        const obj = {
          version: this.searchObj.version
        }
        let countRes = await getNotReviewCountApi(obj)
        if (countRes && countRes.data > 0) {
          this.$confirm('当前尚存在待审核数据，是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            await this.saveAndDownload()
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })        
          })
          return
        } else {
          await this.saveAndDownload()
        }
      },
      // 保存并下载目标数据(选择下载数据的格式)
      async downloadAndSaveHandle() {
        const obj = {
          version: this.searchObj.version
        }
        let countRes = await getNotReviewCountApi(obj)
        if (countRes && countRes.data > 0) {
          this.$confirm('当前尚存在待审核数据，是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            // await this.saveAndDownload()
            this.dialogVisible = true
            if (this.$refs['download-component']) {
              this.$refs['download-component'].dialogVisible = true
            }
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })        
          })
          return
        } else {
          this.dialogVisible = true
          if (this.$refs['download-component']) {
            this.$refs['download-component'].dialogVisible = true
          }
        }
      },
      // 保存并下载调接口
      async saveAndDownload(data) {
        const obj = {
          version: this.searchObj.version,
          downloadType: data.downloadType
        }
        let res = await saveAndDownloadApi(obj)
        onStartDownload(res, () => {
          if (this.$refs['download-component']) {
            this.$refs['download-component'].dialogVisible = false
          }
          // 关闭当前页
          this.$store.dispatch('tagsView/delView', this.$route)
          // 返回到上一页
          this.$router.go(-1)
        })
        if (res.code === 200) {
          onStartDownload(res)
          if (this.$refs['download-component']) {
            this.$refs['download-component'].dialogVisible = false
          }
        }
      },
      // 上一条
      async onPrev(e) {
        console.log('上一条')
        const len = this.tableData.length
        // 翻页
        let lastPageNum = 0 // 最后页数
        let currentPageLastIndex = 0 // 当前页最后一个下标
        lastPageNum = parseInt(this.leftTotal / this.searchObj.pageSize) + 1
        currentPageLastIndex = this.leftTotal % this.searchObj.pageNum
        if (this.searchObj.pageNum > 1) {
          if (this.leftTableIndex > 0) {
            // 不翻页
            --this.leftTableIndex
            this.leftTableCurrentRow = this.tableData[this.leftTableIndex]
            this.queryDetailsObj.id = this.tableData[this.leftTableIndex].id
            this.setCurrent(this.leftTableCurrentRow)
            await this.fetchDetailsData()
          } else if (this.leftTableIndex === 0){
            // 往前翻页
            this.searchObj.pageNum--
            await this.fetchData(this.leftTableIndex, (async () => {
              this.leftTableIndex = this.tableData.length - 1
              this.leftTableCurrentRow = this.tableData[this.leftTableIndex]
              this.setCurrent(this.tableData[this.leftTableIndex])
              await this.fetchDetailsData()
            }))
          }
        } else if (this.searchObj.pageNum === 1) {
          // 第一页
          if (this.leftTableIndex === 0) {
            // 当前已是最后一条数据
            this.$message.warning('当前已是第一条数据')
          } else if (this.leftTableIndex > 0) {
            --this.leftTableIndex
            this.leftTableCurrentRow = this.tableData[this.leftTableIndex]
            this.queryDetailsObj.id = this.tableData[this.leftTableIndex].id
            this.setCurrent(this.leftTableCurrentRow)
            await this.fetchDetailsData()
          }
        }
        this.tableScrollMove('leftTableRef', this.leftTableIndex)
        /* this.leftTableIndex = this.leftTableIndex > 0 ? --this.leftTableIndex : 0
        this.leftTableCurrentRow = this.tableData[this.leftTableIndex]
        this.queryDetailsObj.id = this.tableData[this.leftTableIndex].id
        this.setCurrent(this.leftTableCurrentRow)
        await this.fetchDetailsData() */
      },
      // 下一条
      async onNext(e) {
        console.log('下一条')
        const len = this.tableData.length
        // 翻页
        let lastPageNum = 0 // 最后页数
        let currentPageLastIndex = 0 // 当前页最后一个下标
        lastPageNum = parseInt(this.leftTotal / this.searchObj.pageSize) + 1
        currentPageLastIndex = this.leftTotal % this.searchObj.pageNum
        if (this.searchObj.pageNum < lastPageNum) {
          if (this.leftTableIndex < len - 1) {
            // 不翻页
            ++this.leftTableIndex
            this.leftTableCurrentRow = this.tableData[this.leftTableIndex]
            this.queryDetailsObj.id = this.tableData[this.leftTableIndex].id
            this.setCurrent(this.leftTableCurrentRow)
            await this.fetchDetailsData()
          } else if (this.leftTableIndex === len - 1){
            // 翻页
            this.searchObj.pageNum++
            this.leftTableIndex = 0
            await this.fetchData(this.leftTableIndex)
          }
        } else if (this.searchObj.pageNum === lastPageNum) {
          // 最后一页
          if (this.leftTableIndex === len - 1) {
            // 当前已是最后一条数据
            this.$message.warning('当前已是最后一条数据')
          } else if (this.leftTableIndex < len - 1) {
            ++this.leftTableIndex
            this.leftTableCurrentRow = this.tableData[this.leftTableIndex]
            this.queryDetailsObj.id = this.tableData[this.leftTableIndex].id
            this.setCurrent(this.leftTableCurrentRow)
            await this.fetchDetailsData()
          }
        }
        this.tableScrollMove('leftTableRef', this.leftTableIndex)
        // 当前是最后一页最后一条数据
        /* this.leftTableIndex = this.leftTableIndex === len ? this.leftTableIndex : ++this.leftTableIndex
        this.leftTableCurrentRow = this.tableData[this.leftTableIndex]
        this.queryDetailsObj.id = this.tableData[this.leftTableIndex].id
        this.setCurrent(this.leftTableCurrentRow)
        await this.fetchDetailsData() */
      },
      /**
      * @description: 通过表格索引 定位表格的位置
      * @param {refName} table的ref值
      * @param {index} table的索引值
      */
      tableScrollMove(refName, index = 0) {
        if(!refName || !this.$refs[refName]) return//不存在表格的ref vm 则返回
        let vmEl = this.$refs[refName].$el
        if(!vmEl) return
        //计算滚动条的位置
        const targetTop = vmEl.querySelectorAll('.el-table__body tr')[index].getBoundingClientRect().top
        const containerTop = vmEl.querySelector('.el-table__body').getBoundingClientRect().top
        const scrollParent = vmEl.querySelector('.el-table__body-wrapper')
        scrollParent.scrollTop = targetTop - containerTop
      },
      // 确认审核
      async onConfirm() {
        // 确认审核按钮点击之后，文字变为撤销上一步操作
        const obj = {
          id: this.detailDataObj.id,
          status: 2
        }
        let res = await updateDrugStatusApi(obj)
        if (res.code === 200) {
          this.$message.success("审核成功")
          this.isShowConfirm = false
          await this.fetchData(this.leftTableIndex)
        }
      },
      // 取消审核
      async onCancel() {
        const obj = {
          id: this.detailDataObj.id,
          status: 1
        }
        let res = await updateDrugStatusApi(obj)
        if (res.code === 200) {
          this.$message.success("取消审核成功")
          this.isShowConfirm = true
          await this.fetchData(this.leftTableIndex)
        }
      },
      // 设置审核字段
      onSetField() {
        this.isShowDialog = true
      },
      // 关闭设置审核字段弹窗
      async onCloseDialog(val, sign) {
        this.isShowDialog = val
        if (sign === 'success') {
          await this.fetchData(this.leftTableIndex)
        }
      },
      // 操作图标
      optionClass(row) {
        if ((row.status === 2 || row.status === 1 || row.status === 3) && !row.currUseDefTarget) {
          // 已审核
          return 'recall-icon'
        } else if ((row.status === 2 || row.status === 1 || row.status === 3) && row.currUseDefTarget) {
          // 待审核
          return 'transfer-icon'
        } else {
          // console.log(row)
          return ''
        }
        // row.currUseDefTarget = null 不显示
      },
      statusFormatter(row, column) {
        if (row.status === 1) {
          return '已审核'
        } else if (row.status === 0) {
          return '未审核'
        } else if (row.status === 2) {
          return '不审核'
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
.target-data-review-container {
  background-color: #F0F2F5 !important;
  .container-left {
    width: 60% !important;
    margin-bottom: 0 !important;
    margin-right: 5px;
    padding: 10px 10px 5px !important;
    border-radius: 4px;
    box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
    background-color: #fff;
    .container-left-wrap {
      .left-header {
        padding: 0 !important;
        height: auto !important;
        .left-form-wrap {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }
      }
      .left-main {
        padding: 0 !important;
        .left-table-wrap {
          ::v-deep.el-table__body tr.current-row > td.el-table__cell {
            background-color: #C5D0F0 !important;
          }
          .dis-reviewed {
            background-color: #f1dbd6;
            color: #FF6666;
            border-radius: 3px;
            padding: 5px 0;
            text-align: center;
          }
          .reviewed {
            background-color: #D4FFCC;
            color: #49B812;
            border-radius: 3px;
            padding: 5px 0;
            text-align: center;
          }
          .non-reviewed {
            background-color: #FFEBD6;
            color: #E4860A;
            border-radius: 3px;
            padding: 5px 0;
            text-align: center;
          }
        }
      }
      .left-footer {
        padding: 0 !important;
        height: 40px !important;
        margin-top: 10px;
      }
    }
  }
  .container-right {
    width: 40%;
    margin-left: 5px;
    padding: 10px 10px 5px !important;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
    .right-header {
      height: auto !important;
      padding: 0 !important;
      .right-drug-info {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-bottom: 5px;
        .drug-icon {
          margin-right: 5px;
        }
      }
      .right-form-wrap {
        display: flex;
        justify-content: space-between;
        .el-form-item {
          margin-bottom: 10px !important;
        }
      }
    }
    .right-main {
        padding: 0 !important;
        .right-table-wrap {
          .handle-icon {
            /* cursor: pointer; */
          }
        }
      }
      .right-footer {
        padding: 0 !important;
        height: auto !important;
        margin-top: 10px;
        /* display: flex;
        justify-content: space-between; */
        .el-button {
          padding: 8px 5px !important;
          font-size: 12px !important;
        }
        .el-button + .el-button {
          margin-left: 5px !important;
        }
      }
  }
}
</style>