/**机构药品库 */
import request from '@/utils/request'

// 发布列表-时间轴
export function timelineApi(query) {
  return request({
    url: '/dm/publish/timeline',
    method: 'GET',
    params: query
  })
}

// 机构药品列表
export function orgListApi(query) {
  return request({
    url: '/dm/publish/org/list',
    method: 'GET',
    params: query
  })
}
// 平台标准药品数据
export function platformDrugApi(query) {
  return request({
    url: '/dm/publish/info',
    method: 'GET',
    params: query
  })
}
// 获取下载格式
export function downloadTypeApi(query) {
	return request({
		url: '/dm/publish/download/type',
		method: 'GET',
		params: query
	})
}
// 下载文件
export function downloadDataApi(query) {
	return request({
		url: '/dm/publish/download',
		method: 'GET',
		params: query,
    resopnseReturn: true, // axios数据返回的是一个整体
		responseType: 'blob',
    insideError: true
	})
}
