/**目标数据审核(机构端) */
import request from '@/utils/request'

// 分页查询接口（审核的药品列表）
export function getDrugsListApi(data) {
  return request({
    url: '/dm/publish/final/drug/list',
    method: 'get',
    params: data
  })
}
// 获取药品对比信息
export function getContrastDataApi(data) {
  return request({
    url: '/dm/publish/final/drug/detail',
    method: 'get',
    params: data
  })
}
// 对字段内容进行更新处理(目标数据和原始数据进行转换)
export function updateTargetDataApi(data) {
  return request({
    url: '/dm/publish/final/field',
    method: 'put',
    params: data
  })
}
// 对药品状态进行更新(确认审核或者取消审核)
export function updateDrugStatusApi(data) {
  return request({
    url: '/dm/publish/final/status',
    method: 'put',
    params: data
  })
}
// 查询当前批次审核字段规则
export function getFieldsRuleApi(data) {
  return request({
    url: '/dm/publish/final/dm/conf/field/',
    method: 'get',
    params: data
  })
}
// 更新当前批次审核字段规则
export function updateFieldsRuleApi(data) {
  return request({
    url: '/dm/publish/final/dm/conf/field/',
    method: 'put',
    data
  })
}
// 保存并下载目标数据
export function saveAndDownloadApi(data) {
  return request({
    url: '/dm/publish/final/finishAndDownload?version=' + data.version,
    method: 'get',
    resopnseReturn: true,
    responseType: 'blob'
  })
}
// 仅保存
export function saveApi(data) {
  return request({
    url: '/dm/publish/final/finish',
    method: 'put',
    params: data
  })
}
// 获取尚未审核的药品数量
export function getNotReviewCountApi(data) {
  return request({
    url: '/dm/publish/final/not/approved/count',
    method: 'get',
    params: data
  })
}