<!--目标数据审核提供"保存"、"保存并下载"2种选择-->
<template>
  <el-dialog
		v-if="dialogVisible"
    title="保存"
    :visible="dialogVisible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="download-data-dialog"
    width="330px"
		:append-to-body="true"
    center
    :before-close="handleClose">
    <el-form ref="form" :model="formData" label-position="top" label-width="130px">
		<el-form-item label="请选择保存方式：" class="form-item">
			<el-select v-model="formData.saveType" placeholder="请选择">
				<el-option
					v-for="item in typeOptions"
					:key="item.code"
					:label="item.name"
					:value="item.code" />
			</el-select>
		</el-form-item>
	</el-form>
    <span slot="footer" class="dialog-footer">
			<!-- <el-button type="primary" @click="downloadHandle">下载</el-button> -->
			<el-button @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="onConfirm">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
	downloadTypeApi,
	downloadDataApi
} from '@/api/dm/institutionDrugStorage'
import {
  saveApi,
  saveAndDownloadApi,
  getNotReviewCountApi
} from '@/api/dm/targetDataReview'
import {
  onStartDownload
} from '@/utils/downloadFile'
export default {
  props: {
    version: {
      type: String,
      required: true
    },
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    version: async function(newVal, oldVal) {
      console.log(newVal)
      console.log(oldVal)
      if (newVal !== '') {
        this.formData.version = this.version
        // let res = await downloadTypeApi()
        // if (res && res.code === 200) {
        //   this.typeOptions = res.data
        //   this.formData.saveType = this.typeOptions[0] ? this.typeOptions[0].code : ''
        // }
      }
    }
  },
	data() {
		return {
			// dialogVisible: false,
			typeOptions: [
        {
          code: 1,
          name: '仅保存'
        },
        {
          code: 2,
          name: '保存并下载'
        }
      ],
			formData: {
				saveType: 1,
        version: ''
			}
		}
	},
	async created() {
    console.log(this.version)
	},
	methods: {
		// 关闭对话框
		handleClose(done) {
			/* this.$confirm('确认关闭？')
				.then(_ => {
					done()
				})
				.catch(_ => {}) */
      // console.log(done)
      this.$emit('closeDialog', false)
      done()
		},
    closeDialog() {
      // this.dialogVisible = false
      this.$emit('closeDialog', false)
    },
		// 下载
		async downloadHandle() {
			let res = await downloadDataApi(this.formData)
      onStartDownload(res, this.closeDialog())
		},
    async onConfirm() {
      if (this.formData.saveType === 1) {
        // 仅保存
        await this.saveData()
      } else if (this.formData.saveType === 2) {
        // 保存并下载
        await this.saveAndDownload()
      }
    },
    // 保存并下载目标数据或者
    async onSaveAndDownload() {
      const obj = {
        version: this.version
      }
      let countRes = await getNotReviewCountApi(obj)
      if (countRes && countRes.data > 0) {
        this.$confirm('当前尚存在待审核数据，是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await this.saveAndDownload()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })        
        })
        return
      } else {
        await this.saveAndDownload()
      }
    },
    // 保存并下载调接口
    async saveAndDownload() {
      const obj = {
        version: this.version
      }
      let res = await saveAndDownloadApi(obj)
      onStartDownload(res, () => {
        this.$emit('finish')
      })
      if (res.code === 200) {
        onStartDownload(res)
      }
    },
    // 仅保存
    async saveData() {
      const obj = {
        version: this.version
      }
      let res = await saveApi(obj)
      if (res.code === 200) {
        this.$emit('finish')
      }
    }
	}
}
</script>
<style lang="scss" scoped>
.download-data-dialog {
	::v-deep .el-dialog__body {
		padding: 20px 0 !important;
	}
	::v-deep .form-item {
		label {
			/* display: block;
			width: 100% !important;
			text-align: center; */
			font-size: 14px;
			font-weight: 400;
			color: #333;
			margin-left: 50px;
		}
		.el-form-item__content {
			/* margin-left: 0 !important;
			display: block;
			text-align: center; */
			margin-left: 50px;
		}
	}
	::v-deep .el-dialog__footer {
		.el-button {
			padding: 10px 40px !important;
		}
	}
}
</style>
