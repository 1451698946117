var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShowDialog
    ? _c(
        "el-dialog",
        {
          staticClass: "vertical-middle-dialog target-data-review-dialog",
          attrs: {
            title: "设置审核字段",
            visible: _vm.isShowDialog,
            width: "500px",
            center: "",
          },
          on: { close: _vm.closeDialog },
        },
        [
          _c(
            "el-table",
            {
              staticClass: "dialog-table-wrap",
              attrs: {
                data: _vm.tableData,
                "header-cell-style": { textAlign: "center", color: "#333" },
                border: "",
                height: "100%",
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "100" },
              }),
              _vm._v("> "),
              _c("el-table-column", {
                attrs: { prop: "fieldName", label: "字段名称" },
              }),
              _vm._v("> "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "审核设置",
                  align: "center",
                  width: "100",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-switch", {
                            staticClass: "switchStyle",
                            attrs: {
                              "active-color": "#5FCC29",
                              "active-text": "是",
                              "inactive-color": "#FF6666",
                              "inactive-text": "否",
                            },
                            model: {
                              value: scope.row.statusBoolean,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "statusBoolean", $$v)
                              },
                              expression: "scope.row.statusBoolean",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  848291605
                ),
              }),
              _vm._v("> "),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }