/***目标数据审核 */
export const QUERY_OBJ = {
  drugId: '',
  isAsc: '', // 排序的方向desc或者asc
  orderByColumn: '', // 排序列	
  searchValue: '', // 药品通用名/生产厂家
  pageNum: 1,
  pageSize: 10,
  status: 1, // 全部/已审核/未审核/不审核
  version: '' // 版本号
}
// 获取右侧药品字段比对信息
export const QUERY_DETAILS_OBJ = {
 id: '',
 containDisable: 1, // 包含禁用,可用值:0,1
 status: 1 // 状态 1: 待审核 2:已审核 3:无需审核,可用值:1,2,3
}
