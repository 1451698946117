var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "download-data-dialog",
          attrs: {
            title: "保存",
            visible: _vm.dialogVisible,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "330px",
            "append-to-body": true,
            center: "",
            "before-close": _vm.handleClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                "label-position": "top",
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "form-item",
                  attrs: { label: "请选择保存方式：" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formData.saveType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "saveType", $$v)
                        },
                        expression: "formData.saveType",
                      },
                    },
                    _vm._l(_vm.typeOptions, function (item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }