var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout-container target-data-review-container" },
    [
      _c(
        "el-aside",
        { staticClass: "container-left" },
        [
          _c(
            "el-container",
            { staticClass: "layout-container container-left-wrap" },
            [
              _c(
                "el-header",
                { staticClass: "left-header" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "left-form-wrap",
                      attrs: { inline: true, model: _vm.searchObj },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "small" },
                                  on: { change: _vm.onChangeStatus },
                                  model: {
                                    value: _vm.searchObj.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchObj, "status", $$v)
                                    },
                                    expression: "searchObj.status",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "" } },
                                    [_vm._v("全部")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: 1 } },
                                    [_vm._v("待审核")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: 2 } },
                                    [_vm._v("已审核")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "210px" },
                                attrs: {
                                  placeholder: "药品通用名/生产厂家/药品ID",
                                  size: "small",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchObj.searchValue,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchObj, "searchValue", $$v)
                                  },
                                  expression: "searchObj.searchValue",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-search",
                                    size: "small",
                                  },
                                  on: { click: _vm.onSearch },
                                },
                                [_vm._v("查询")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-s-claim",
                                    size: "small",
                                  },
                                  on: { click: _vm.onSave },
                                },
                                [_vm._v(" 保存 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-download",
                                    size: "small",
                                  },
                                  on: { click: _vm.downloadAndSaveHandle },
                                },
                                [_vm._v("保存并下载目标数据")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-back",
                                    size: "small",
                                  },
                                  on: { click: _vm.onBack },
                                },
                                [_vm._v("返回")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-main",
                { staticClass: "left-main" },
                [
                  _c(
                    "el-table",
                    {
                      ref: "leftTableRef",
                      staticClass: "left-table-wrap",
                      attrs: {
                        data: _vm.tableData,
                        "header-cell-style": {
                          textAlign: "center",
                          color: "#333",
                        },
                        border: "",
                        stripe: "",
                        "highlight-current-row": "",
                        height: "100%",
                      },
                      on: { "row-click": _vm.leftTableRowClick },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "drugId",
                          label: "药品ID",
                          width: "100",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "genericName",
                          label: "药品通用名",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "pdpSpecPackTxt", label: "规格包装" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "specText",
                          label: "药品规格说明",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "manufacturerName",
                          label: "生产厂家",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "status",
                          label: "审核状态",
                          width: "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  { class: _vm.statusClass(scope.row.status) },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.fullStatusStr)),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-footer",
                { staticClass: "left-footer" },
                [
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.leftTotal > 0,
                        expression: "leftTotal > 0",
                      },
                    ],
                    attrs: {
                      total: _vm.leftTotal,
                      page: _vm.searchObj.pageNum,
                      limit: _vm.searchObj.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.searchObj, "pageNum", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.searchObj, "pageSize", $event)
                      },
                      pagination: _vm.getTableHandle,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-container",
        { staticClass: "layout-container container-right" },
        [
          _c(
            "el-header",
            { staticClass: "right-header" },
            [
              _c(
                "h3",
                { staticClass: "right-drug-info" },
                [
                  _c("svg-icon", {
                    staticClass: "drug-icon",
                    attrs: { "icon-class": "drug-icon", fill: "#2B60F8" },
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.detailDataObj.drugInfo))]),
                ],
                1
              ),
              _c(
                "el-form",
                {
                  staticClass: "right-form-wrap",
                  attrs: { inline: true, model: _vm.queryDetailsObj },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "small" },
                              on: { change: _vm.fetchDetailsData },
                              model: {
                                value: _vm.queryDetailsObj.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryDetailsObj, "status", $$v)
                                },
                                expression: "queryDetailsObj.status",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v("全部"),
                              ]),
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v("待审核"),
                              ]),
                              _c("el-radio-button", { attrs: { label: 2 } }, [
                                _vm._v("已审核"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.onSetField },
                            },
                            [_vm._v("设置审核字段")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-main",
            { staticClass: "right-main" },
            [
              _c(
                "el-table",
                {
                  staticClass: "right-table-wrap",
                  attrs: {
                    data: _vm.tableDetailData,
                    "header-cell-style": { textAlign: "center", color: "#333" },
                    "cell-style": _vm.rightTableCellStyle,
                    border: "",
                    height: "100%",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "fieldName", label: "字段名称" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "originName", label: "原始数据" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "80", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.isShowOperationSvg(scope.row)
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      disabled: _vm.isTransferBtnDisabled,
                                      type: "text",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onTransfer(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm.isShowOperationSvg(scope.row)
                                      ? _c("svg-icon", {
                                          staticClass: "handle-icon",
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                          },
                                          attrs: {
                                            "icon-class": _vm.optionClass(
                                              scope.row
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "targetName", label: "目标数据" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-footer",
            { staticClass: "right-footer" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { margin: "10px 0" },
                      attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-warning",
                        staticStyle: {
                          color: "#94AFF7",
                          "vertical-align": "middle",
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-left": "3px",
                            "font-size": "12px",
                            color: "#999",
                          },
                        },
                        [_vm._v("系统所展示药品信息，仅供参考，不作为使用依据")]
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-end",
                      },
                      attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "small" },
                          on: { click: _vm.onPrev },
                        },
                        [_vm._v("上一条(F1)")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "small" },
                          on: { click: _vm.onNext },
                        },
                        [_vm._v("下一条(F2)")]
                      ),
                      _vm.isShowConfirm
                        ? _c(
                            "el-button",
                            {
                              staticClass: "confirm-btn",
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.onConfirm },
                            },
                            [_vm._v(" 确认审核(F3) ")]
                          )
                        : _c(
                            "el-button",
                            {
                              staticClass: "cancel-btn",
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.onCancel },
                            },
                            [_vm._v(" 撤销审核(F3) ")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isShowDialog
        ? _c("field-dialog", {
            attrs: {
              "is-show-dialog": _vm.isShowDialog,
              version: this.searchObj.version,
            },
            on: { closeDialog: _vm.onCloseDialog },
          })
        : _vm._e(),
      _vm.isShowSaveDialog
        ? _c("save-dialog", {
            attrs: {
              "dialog-visible": _vm.isShowSaveDialog,
              version: this.searchObj.version,
            },
            on: {
              finish: _vm.closeAndBack,
              closeDialog: _vm.onCloseSaveDialog,
            },
          })
        : _vm._e(),
      _c("download-dialog", {
        ref: "download-component",
        attrs: { version: _vm.searchObj.version },
        on: { download: _vm.saveAndDownload },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }