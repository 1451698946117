<!--设置审核字段-->
<template>
  <el-dialog
		v-if="isShowDialog"
		title="设置审核字段"
		:visible="isShowDialog"
    @close="closeDialog"
		width="500px"
    class="vertical-middle-dialog target-data-review-dialog"
		center>
    <el-table
      :data="tableData"
      :header-cell-style="{textAlign: 'center', color: '#333'}"
      border
      height="100%"
      class="dialog-table-wrap">
      <el-table-column
        type="index"
        label="序号"
        width="100">
      </el-table-column>>
      <el-table-column
        prop="fieldName"
        label="字段名称">
      </el-table-column>>
      <el-table-column
        prop="status"
        label="审核设置"
        align="center"
        width="100">
      <template slot-scope="scope">
        <el-switch
          v-model="scope.row.statusBoolean"
          class="switchStyle"
          active-color="#5FCC29"
          active-text="是" 
          inactive-color="#FF6666"
          inactive-text="否">
        </el-switch>
      </template>
      </el-table-column>>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="onConfirm">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import {
    getFieldsRuleApi,
    updateFieldsRuleApi
  } from '@/api/dm/targetDataReview'
  export default {
    props: {
      isShowDialog: {
        type: Boolean,
        required: true
      },
      version: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        tableData: []
      }
    },
    computed: {
      // 判断是否是上海的机构
      showShangHai() {
        const { provinceCode } = this.$store.getters.dept
        if (provinceCode === '310000') {
          return true
        } else {
          return false
        }
      }
    },
    watch: {
      async version(newVal, oldVal) {
        if (newVal && oldVal) {
          await this.fetchData()
        }
      }
    },
    async created() {
      await this.fetchData()
    },
    methods: {
      async fetchData() {
        const obj = {
          version: this.version
        }
        let res = await getFieldsRuleApi(obj)
        if (res.code === 200) {
          res.data && res.data.forEach(item => {
            if (item.status === 1) {
              item.statusBoolean = true
            } else if (item.status === 2) {
              item.statusBoolean = false
            }
          })
          if (this.showShangHai) {
            this.tableData = res.data || []
          } else {
            let data = res.data && res.data.filter(item => {
              return item.fieldCode !== 'ustdCode' && item.fieldCode !== 'shNedFlag'
            })
            this.tableData = data || []
          }
        }
      },
      closeDialog() {
        this.$emit('closeDialog', false)
      },
      async onConfirm() {
        const obj = {
          confFieldList: this.tableData
        }
        this.tableData.forEach(item => {
          item.status = item.statusBoolean ? 1 : 2
        })
        let res = await updateFieldsRuleApi(this.tableData)
        if (res.code === 200) {
          await this.fetchData()
          this.$message.success("设置成功")
          this.$emit('closeDialog', false, 'success')
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .target-data-review-dialog {
    .dialog-table-wrap {
      .el-switch.switchStyle {
        height: auto !important;
        ::v-deep.el-switch__label {
          position: absolute;
          display: none;
          color: #fff;
          font-size: 12px !important;
        }
        ::v-deep.el-switch__label--left {
          z-index: 9;
          left: 7px;
        }
        ::v-deep.el-switch__label--right {
          z-index: 9;
          left: 0px;
        }
        ::v-deep.el-switch__label.is-active {
          display: block;
        }
        ::v-deep.el-switch__core, .el-switch__label {
          width: 60px !important;
          /* height: 26px; */
        }
        ::v-deep.el-switch__label * {
          font-size: 12px;
        }
      } 
    }
  }
  
</style>
